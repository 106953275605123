import ContainerMain from "pages/common/ContainerMain"
import icon4 from "images/Product/Integrated/icon-4.svg"
import icon5 from "images/Product/Integrated/icon-5.svg"
import iconDollar from "images/Product/Integrated/icon-dollar.svg"
import iconEmpty from "images/Product/Integrated/icon-empty.svg"
import iconOcto from "images/Product/Integrated/icon-octo.svg"
import imgFooter from "images/Product/Integrated/img-footer.svg"
import "./Business.scss"
import { useFormatter } from "helpers/i18n"

export default function Business() {
  const { __ } = useFormatter()
  return (
    <>
      <div className="gWVJYTPoZM-business section-title">
        {__(
          { defaultMessage: "The Ideal Solution for{br}Your Business" },
          { br: <br /> }
        )}
      </div>
      <div className="ySdxRuIrWl">
        <img className="img_footer" alt="" src={imgFooter} />
        <div className="DsjHwbzcYM">
          <div className="iIoAFXiiVv">
            <ContainerMain>
              <div className="xlATuGuOWH">
                <div className="wKmcPquIAk XcuDlUYJlu">
                  <div className="XWNQPEjlYP"></div>
                  <div className="IbXdiggGgw">
                    <ul className="uWUSbzIcsz">
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Real-time Inventory" })}
                      </li>
                      <li className="QdaRQtLWyi">
                        <p style={{ whiteSpace: "nowrap", marginBottom: 0 }}>
                          {__({ defaultMessage: "Multi-platform Integration" })}
                        </p>
                      </li>
                      <li className="QdaRQtLWyi">
                        <p style={{ whiteSpace: "nowrap", marginBottom: 0 }}>
                          {__({ defaultMessage: "Capability to scale up" })}
                        </p>
                      </li>
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Stability" })}
                      </li>
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Usability" })}
                      </li>
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Mobile application" })}
                      </li>
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Reliability" })}
                      </li>
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Level of support" })}
                      </li>
                      <li className="QdaRQtLWyi">
                        {__({ defaultMessage: "Custom Features" })}
                      </li>
                      <li className="QdaRQtLWyi" style={{ marginTop: 4 }}>
                        {__({ defaultMessage: "Price" })}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wKmcPquIAk">
                  <div className="flex__item">
                    <div className="wZgJmRTYpR">
                      <div className="wqWGEeuyVP">
                        <div className="nsaBowjIRI">
                          {__({
                            defaultMessage:
                              "Self-management & small warehouse management system solution",
                          })}
                        </div>
                        <div className="ZcPsNkIJyR">
                          {__({
                            defaultMessage:
                              "Basic features, low-cost, unscalable",
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="IbXdiggGgw">
                      <div className="NPTdKWMcCE">
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon5} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon5} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Normal" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Normal" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon5} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Unreliable when scale up" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Slow" })}
                        </div>
                        <div className="xQfqqWXoyC JEUdKojqbv">
                          <img alt="" src={icon5} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC HKCPBIbYrQ">
                          <div className="ZnlsXoivfQ">
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                            <div className="LCcESLlexr">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconEmpty}
                              />
                            </div>
                            <div className="LCcESLlexr">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconEmpty}
                              />
                            </div>
                            <div className="LCcESLlexr">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconEmpty}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wKmcPquIAk">
                  <div className="VTRODiBYyQ">
                    <div className="qtkGtkutij">
                      <div className="CVIxHmYRbJ">
                        <div className="ZPyYTcYqwl">
                          <div className="ZVzktPlDSh">
                            <img
                              alt="pXdYEnORCT"
                              src={iconOcto}
                              className="tviIaeWcIL"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="PYQEVuzkAS">
                        <div className="ZcPsNkIJyR">
                          {__({
                            defaultMessage:
                              "Highly reliable & instantly scalable. Easy to use & proactive support. Mid-range price",
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="IbXdiggGgw">
                      <div className="NPTdKWMcCE">
                        <div className="flex__item--highlight"></div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "High" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Good" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Easy" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Quick" })}
                        </div>
                        <div className="xQfqqWXoyC JEUdKojqbv">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC HKCPBIbYrQ">
                          <div className="ZnlsXoivfQ">
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                            <div className="LCcESLlexr">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconEmpty}
                              />
                            </div>
                            <div className="LCcESLlexr">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconEmpty}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ijswMkHqyo"></div>
                    </div>
                  </div>
                </div>
                <div className="wKmcPquIAk">
                  <div className="IbXdiggGgw">
                    <div className="flex__item">
                      <div className="wZgJmRTYpR">
                        <div className="wqWGEeuyVP">
                          <div className="nsaBowjIRI">
                            {__({
                              defaultMessage: "Enterprise cloud ERP solutions",
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="NPTdKWMcCE">
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "High" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Good" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Complex" })}
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon5} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC">
                          {__({ defaultMessage: "Slow" })}
                        </div>
                        <div className="xQfqqWXoyC JEUdKojqbv">
                          <img alt="" src={icon4} className="YpmQpTetXG" />
                        </div>
                        <div className="xQfqqWXoyC HKCPBIbYrQ">
                          <div className="ZnlsXoivfQ">
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                            <div className="LCcESLlexr YOZCCgreuB">
                              <img
                                alt=""
                                className="geOGjZnaCh"
                                src={iconDollar}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ContainerMain>
          </div>
        </div>
      </div>
    </>
  )
}
